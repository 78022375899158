import { db } from "../../services/firebase/Config";
import { SET_CENTRORX } from "./centroRxTypes";
import {
  httpRequest,
  httpSuccess,
  httpFailure,
  setUsuarioSesion,
} from "../sesion/sesionActions";
import {
  getCentroRxById,
  saveEmpresa,
} from "../../services/firebase/centrosRx/index";

let itemsTarifarioBase = require("./tarifarioBase.json");
export const setCentroRx = (empresa) => {
  return {
    type: SET_CENTRORX,
    payload: empresa,
  };
};

export const getCentroRx = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    getCentroRxById(empresaId)
      .then((empresa) => {
        dispatch(httpSuccess());
        dispatch(setCentroRx(empresa));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaCentroRx = (empresa) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveEmpresa(empresa)
      .then(() => {
        dispatch(setCentroRx(empresa));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaCentroRxUsuario = (empresa, usuario) => {
  return function (dispatch) {
    var batch = db.batch();
    let empresasRef = db.collection("centrosRx").doc();
    let usuariosRef = db.collection("usuarios").doc();
    let tarifariosCrxRef = db.collection("tarifariosCrx").doc();
    dispatch(httpRequest());
    usuario.centroRxId = empresasRef.id;
    usuario.cargo = "GERENTE";
    usuario.perfil = "ODONTOLOGO";
    usuario.fecha = new Date();
    usuario.id = usuariosRef.id;
    empresa.id = empresasRef.id;
    empresa.email = usuario.email;

    let miTarifario = {
      centroRxId: empresasRef.id,
      id: tarifariosCrxRef.id,
      items: itemsTarifarioBase,
      fecha: new Date(),
    };
    batch.set(empresasRef, empresa);
    batch.set(usuariosRef, usuario);
    batch.set(tarifariosCrxRef, miTarifario);

    batch
      .commit()
      .then(() => {
        dispatch(setCentroRx(empresa));
        dispatch(setUsuarioSesion(usuario));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
