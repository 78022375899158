import React from "react";
import {
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DienteContenedorCorona,
  DienteContenedorRaiz,
  Img,
} from "../Styles/dientes.css";

export const DienteInferior = ({ item, onSelectedTooth, checked }) => {
  const { diente, nombre, patologia } = item;
  const imgCorona = require(`../svg/incisivo.svg`);
  const imgDiente = require(`../svg/${nombre}.svg`);

  return (
    <DienteContenedor>
      <ContenedorAuxiliar>
        <DienteContenedorNumero>
          <input type="checkbox" id={diente} name="dientes" checked={checked} />
        </DienteContenedorNumero>
        <DienteContenedorCorona>{diente}</DienteContenedorCorona>
      </ContenedorAuxiliar>
      <DienteContenedorRaiz>
        <Img src={imgDiente} />
      </DienteContenedorRaiz>
    </DienteContenedor>
  );
};
