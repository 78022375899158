export const Menus = [
  {
    menu: "lateral",
    key: "ordenes",
    perfil: "ADMIN",
    nombre: "Ordenes",
    iconFile: "azul_calendario.png",
    url: "/ordenes",
    items: [
      {
        nombre: "Pendientes",
        url: "/pendientes",
        iconFile: "azul_generar_32.png",
      },
      {
        nombre: "Atendidas",
        url: "/atendidas",
        iconFile: "azul_generar_32.png",
      },
    ],
  },

  {
    menu: "lateral",
    key: "admin",
    perfil: "ADMIN",
    nombre: "Administracion",
    iconFile: "azul_laboratorio.png",
    url: "/admin",
    items: [
      {
        nombre: "Tarifario",
        url: "/tarifario",
        iconFile: "azul_generar_32.png",
      },
      {
        nombre: "Empresa",
        url: "/empresa",
        iconFile: "azul_reportes_32.png",
      },
    ],
  },
];

export const getMenus = () => {
  return Menus.filter(
    (item) => item.menu === "lateral" && item.perfil === "ADMIN"
  );
};
