import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const FlowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  display: flex;
  justify-content: center;
`;

export const LeftFlowContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
`;
