import { db } from "../../services/firebase/Config";
import {
  SET_ORDENES,
  UPDATE_ORDEN_IN_ARRAY,
  ADD_ORDEN_IN_ARRAY,
} from "./ordenesRxTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { COrden } from "../../Entidades/Orden";
import {
  fetchOrdenesRx,
  fetchOrdenesRxEmpresa,
  fetchOrdenesRxPaciente,
  fetchOrdenesAtendidasEmpresa,
} from "../../services/firebase/ordenesRx";

export const setOrdenes = (ordenes) => {
  return {
    type: SET_ORDENES,
    payload: ordenes,
  };
};

export const updateOrdenInArray = (orden) => {
  return {
    type: UPDATE_ORDEN_IN_ARRAY,
    payload: orden,
  };
};

export const addOrdenInArray = (orden) => {
  return {
    type: ADD_ORDEN_IN_ARRAY,
    payload: orden,
  };
};

export const getOrdenesLab = (labId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesRx(labId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getAllOrdenes = () => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesRx()
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getOrdenesEmpresa = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesRxEmpresa(empresaId)
      .then((ordenes) => {
        console.log(ordenes);
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getOrdenesAtendidasEmpresa = (empresaId, fecIni, fecFin) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesAtendidasEmpresa(empresaId, fecIni, fecFin)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getOrdenesRxPaciente = (pacienteId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesRxPaciente(pacienteId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
