import { db } from "../../services/firebase/Config";
import {
  SET_ORDEN,
  NEW_ORDEN,
  ADD_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
} from "./ordenRxTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import {
  updateOrdenInArray,
  addOrdenInArray,
} from "../ordenesRx/ordenesRxActions";

export const setOrden = (orden) => {
  return {
    type: SET_ORDEN,
    payload: orden,
  };
};
export const newOrden = () => {
  return {
    type: NEW_ORDEN,
  };
};

export const addItem = (item) => {
  return {
    type: ADD_ITEM,
    payload: item,
  };
};

export const removeItem = (item) => {
  return {
    type: REMOVE_ITEM,
    payload: item,
  };
};

export const updateItem = (item) => {
  return {
    type: UPDATE_ITEM,
    payload: item,
  };
};

var ordenConverter = {
  toFirestore: function (orden) {
    return {
      id: orden.id,
      estado: orden.estado,
      fecEnv: orden.fecEnv,
      fecTer: orden.fecTer,
      centroRxId: orden.centroRxId,
      centroRxNombre: orden.centroRxNombre,
      pacienteId: orden.pacienteId,
      pacienteNombres: orden.pacienteNombres,
      empresaId: orden.empresaId,
      items: orden.items,
      empresa: orden.empresa,
      comentarios: orden.comentarios,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: data().id,
      estado: data().estado,
      fecEnv: data().fecEnv,
      fecTer: data().fecTer,
      centroRxId: data().centroRxId,
      centroRxNombre: data().centroRxNombre,
      pacienteId: data().pacienteId,
      pacienteNombres: data().pacienteNombres,
      empresaId: data().empresaId,
      items: data().items,
      empresa: data().empresa,
      comentarios: data().comentarios,
    };
  },
  toDate(fecha) {
    if (typeof fecha === "string") return new Date(fecha);
  },
  toCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  },
};

export const actualizaOrdenRx = (orden) => {
  return function (dispatch) {
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());
    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(setOrden(orden));
        dispatch(updateOrdenInArray(orden));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const actStsOrdenes = (itemsOrden, tarifario, sts) => {
  // por grupo

  let itemsConta = itemsOrden.filter(
    (it) =>
      tarifario.items.some(
        (tarifa) => tarifa.llave === it.llave && tarifa.activo
      ) &&
      it.checked &&
      !it.atendido
  );
  itemsConta = itemsConta.map((it) => {
    //recupera la llave
    let tarifa = tarifario.items.find(
      (tr) => tr.llave === it.llave && tr.grupo === it.grupo
    );
    return { ...it, precio: Number(tarifa.valor) };
  });
  //se adiciona a las estadisticas
  itemsConta.forEach((itemOrden) => {
    let idx = sts.grupos.findIndex((it) => it.grupo === itemOrden.grupo);
    sts.total += itemOrden.precio;

    if (idx > -1) {
      let grupo = sts.grupos[idx];
      grupo.cantidad++;
      grupo.total += itemOrden.precio;
      grupo.grupo = itemOrden.grupo;

      let idy = grupo.items.findIndex((it) => it.llave === itemOrden.llave);
      if (idy > -1) {
        let elemento = grupo.items[idy];
        elemento.cantidad++;
        elemento.total += itemOrden.precio;
        elemento.llave = itemOrden.llave;
        elemento.descripcion = itemOrden.descripcion;
      } else {
        grupo.filas++;
        grupo.items.push({
          cantidad: 1,
          llave: itemOrden.llave,
          total: itemOrden.precio,
          descripcion: itemOrden.descripcion,
        });
      }
    } else {
      sts.grupos.push({
        cantidad: 1,
        total: itemOrden.precio,
        grupo: itemOrden.grupo,
        filas: 1,
        items: [
          {
            llave: itemOrden.llave,
            cantidad: 1,
            total: itemOrden.precio,
            descripcion: itemOrden.descripcion,
          },
        ],
      });
    }
  });
};

export const atenderOrdenRx = (orden, tarifario) => {
  return function (dispatch) {
    const miOrden = { ...orden };
    dispatch(httpRequest());
    const batch = db.batch();
    const ordenesRef = db.collection("ordenesRx").doc(orden.id);
    let stsOrdenesRef = db.collection("stsOrdenesRx");
    const transaccion = async () => {
      let month = new Date().getMonth();
      let year = new Date().getFullYear();

      //recupero documento de estadisticas
      let sts = {
        id: "",
        empresaId: orden.empresaId,
        empresa: orden.empresa,
        centroRxId: orden.centroRxId,
        centroRxNombre: orden.centroRxNombre,
        month: month,
        year: year,
        filas: 0,
        grupos: [],
        total: 0,
      };
      let querySnapshot = await stsOrdenesRef
        .where("centroRxId", "==", orden.centroRxId)
        .where("empresaId", "==", orden.empresaId)
        .where("year", "==", year)
        .where("month", "==", month)
        .get();

      querySnapshot.forEach((doc) => {
        sts = doc.data();
        sts.id = doc.id;
      });

      actStsOrdenes(orden.items, tarifario, sts);
      let stsOrdenRef;
      if (sts.id) {
        stsOrdenRef = db.collection("stsOrdenesRx").doc(sts.id);
      } else {
        stsOrdenRef = db.collection("stsOrdenesRx").doc();
        sts.id = stsOrdenRef.id;
      }

      miOrden.items
        .filter((it) => it.checked)
        .forEach((it) => {
          it.atendido = true;
        });
      batch.set(ordenesRef, miOrden);
      batch.set(stsOrdenRef, sts);
      batch
        .commit()
        .then(() => {
          dispatch(setOrden(miOrden));
          dispatch(updateOrdenInArray(miOrden));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };
    transaccion();
  };
};

export const nuevaOrdenRx = (orden) => {
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenesRx").doc();
    dispatch(httpRequest());
    orden.id = ordenRef.id;
    batch.set(ordenRef, orden);

    batch
      .commit()
      .then(() => {
        dispatch(addOrdenInArray(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const sendOrden = (orden) => {
  return function (dispatch) {
    const ENVIADO = "ENVIADO";
    orden.estado = ENVIADO;
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());
    console.log("ingreso aqui ordenrx actualizada", orden.id);
    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(updateOrdenInArray(orden));
        console.log("ordenrx actualizada");
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
        console.log("error al guardar documento" + err);
      });
  };
};

export const anulaOrden = (orden) => {
  return function (dispatch) {
    const ANULADO = "ANULADO";
    orden.estado = ANULADO;
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());

    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(updateOrdenInArray(orden));
        dispatch(setOrden(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
        console.log("error al guardar documento" + err);
      });
  };
};
