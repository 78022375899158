import React, { useState, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import { auth } from "../../services/firebase/Config";
import firebase from "firebase/app";
import {
  setUsuarioSesion,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../../redux/sesion/sesionActions";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import EjemploSlider from "../../img/slider/ejemploSlider.png";
import EjemploSlider2 from "../../img/slider/ejemploSlider2.png";
import Sysdent from "../../img/sysdent-logo.png";
import { fetchUsuario } from "../../services/firebase/usuarios";
import { useUser } from "reactfire";
import { useToasts } from "react-toast-notifications";
import { useForm } from "../../components/Hooks/useForm";
import {
  LoginViewWrapper,
  FooterWrapper,
  LoginView,
  SectionForm,
  ImgLogo,
} from "./styles";
import { AuthContext } from "../../components/Auth.js";
import Carousel from "react-bootstrap/Carousel";
const Login = () => {
  console.log("inicio de todo");
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [{ email, password }, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const { currentUser } = useContext(AuthContext);
  const getUsuario = async (email) => {
    let usuario;
    try {
      dispatch(httpRequest());
      usuario = await fetchUsuario(email);
    } catch (err) {
      dispatch(httpFailure());
      setIsLoading(false);
      throw err;
    }
    dispatch(setUsuarioSesion(usuario));
    dispatch(httpSuccess());
    return usuario;
  };

  const verificaUsuario = async (email) => {
    let usuario = await fetchUsuario(email);
    if (usuario.centroRxId) {
      dispatch(setUsuarioSesion(usuario));
      history.push("/ordenes/pendientes");
    } else
      addToast(
        "No encontramos tu cuenta!, crea una cuenta para que puedas acceder",
        { appearance: "error" }
      );
  };

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((login) => {
        verificaUsuario(login.user.email);
      })
      .catch((err) => auth.signOut());
  };

  const handleLogin = () => {
    setIsLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        setIsLoading(false);
        history.push("/");
      })
      .catch((err) => {
        auth.signOut();
        addToast(err.message, { appearance: "warning" });
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLoginWithGoogle();
  };

  const renderLoginForm = () => {
    return !isLoading ? (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>Ingresa a tu cuenta</p>
        </div>
        <form onSubmit={handleSubmit}>
          <button className="form__button" type="submit">
            Ingresar con Google
          </button>
          <div className="form__forgotpassword__container">
            <span>¿Aún no tienes una cuenta? </span>
            <Link to="/register" className="changeLink">
              CREAR CUENTA
            </Link>
          </div>
        </form>
      </div>
    ) : (
      <>
        <CircularProgress color="secondary" />
      </>
    );
  };
  React.useEffect(() => {
    console.log("currentUser", currentUser);
    if (currentUser) {
      let usuario = getUsuario(currentUser.email);
      dispatch(setUsuarioSesion(usuario));
      return <Redirect to="/" />;
    }
  }, []);
  return (
    <LoginViewWrapper>
      <LoginView>
        <SectionForm>
          {renderLoginForm()}
        </SectionForm>
        <FooterWrapper>
          <Carousel variant="dark" class="carouselHeigth">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider2}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </FooterWrapper>
      </LoginView>
    </LoginViewWrapper>
  );
};
export default withRouter(Login);
