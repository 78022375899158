import { db } from "../../services/firebase/Config";
import { SET_TARIFARIO } from "./tarifarioTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import {
  fetchTarifario,
  saveTarifario,
} from "../../services/firebase/tarifario";
export const setTarifario = (tarifario) => {
  return {
    type: SET_TARIFARIO,
    payload: tarifario,
  };
};

export const getTarifario = (centroRxId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchTarifario(centroRxId)
      .then((tarifario) => {
        dispatch(httpSuccess());
        dispatch(setTarifario(tarifario));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaTarifario = (tarifario) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveTarifario(tarifario)
      .then(() => {})
      .catch((err) => dispatch(httpFailure(err)));
  };
};
