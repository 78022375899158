import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrden, actualizaOrdenRx } from "../../redux/ordenRx/ordenRxActions";
import { getOrdenesEmpresa } from "../../redux/ordenesRx/ordenesRxActions";
import { getEmpresas } from "../../services/firebase/empresas/index";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { Modal } from "../../components/Modal/index";
import { OrdenRx } from "../OrdenRx/index";
import { TerminarOrdenRx } from "../../components/TerminarOrdenRx";
import Zoom from "@material-ui/core/Zoom";
import {
  MainContainer,
  ContainerTitle,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  ContainerTitleText,
  ContainerTitleOrden,
  ContenedorTabla,
  ContainerBody,
  TablaBodyFinanzas,
  TablaBodyTextoFinanzaPrecio,
  TablaBodyTextoFinanzaPagado,
  TablaBodyTextoSaldo,
  TablaFinanzas,
  FlexContainer,
  StyledLabel,
  StyledInput,
  SelectStyle,
  TablaBodyTexto,
  Btn,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import { ESTADOS } from "../../Entidades/Orden";
const EVENTOS = {
  VER_ORDEN: "VER_ORDEN",
  TERMINAR_ORDEN: "TERMINAR_ORDEN",
};
const Fila = ({ item, handleEvento }) => {
  function fechaToCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  }
  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="fecEnvio">
        {fechaToCadena(item.fecEnv)}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Consultorio">{item.empresa}</TablaBodyTexto>
      <TablaBodyTexto data-label="Paciente">
        {`${item.paciente.apellidos} ${item.paciente.nombres}`}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Estado">{item.estado}</TablaBodyTexto>
      <TablaBodyTexto data-label="Accion">
        {item.estado == ESTADOS.SOLICITADO && (
          <IconButton
            aria-label=""
            onClick={() => handleEvento(item, EVENTOS.VER_ORDEN)}
            isShown={true}
          >
            <Tooltip TransitionComponent={Zoom} placement="top" title="Ver">
              <VisibilityIcon style={{ color: "#0089ff" }} />
            </Tooltip>
          </IconButton>
        )}
      </TablaBodyTexto>
    </tr>
  );
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      <tr>
        <td>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(precio).toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </td>
        <td>
          <TablaBodyTextoFinanzaPagado>
            Pagado: S/ {Number(pagado).toFixed(2)}
          </TablaBodyTextoFinanzaPagado>
        </td>
        <td>
          <TablaBodyTextoSaldo>
            Saldo: S/ {Number(precio - pagado).toFixed(2)}
          </TablaBodyTextoSaldo>
        </td>
      </tr>
    </TablaBodyFinanzas>
  </TablaFinanzas>
);
export const OrdenesTable = ({ items, handleEvento }) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Fec. Envio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Consultorio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Accion</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {items.map((item) => {
          return <Fila key={item.id} item={item} handleEvento={handleEvento} />;
        })}
      </TablaBody>
    </Tabla>
  );
};

const useOrdenes = (ordenes) => {
  const [empresaId, setEmpresaId] = React.useState("");
  const [query, setQuery] = React.useState("");

  ordenes.sort(function (a, b) {
    if (a.fecEnv < b.fecEnv) {
      return 1;
    }
    if (a.fecEnv > b.fecEnv) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  const [filteredItems, setFilteredItems] = React.useState(ordenes);

  React.useMemo(() => {
    let result = ordenes;
    if (query && empresaId && ordenes.length > 0) {
      console.log("EMPRESA ID && QUERY..");
      result = ordenes.filter((orden) => {
        return (
          orden.pacienteNombres
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) &&
          orden.empresaId == empresaId &&
          orden.estado !== ESTADOS.TERMINADO &&
          orden.estado !== ESTADOS.PENDIENTE
        );
      });
    } else if (empresaId) {
      console.log("EMPRESA ID ..", empresaId, "ESTADO");
      result = ordenes.filter((orden) => {
        return orden.empresaId == empresaId;
      });
    } else if (query) {
      console.log("QUERY ..");
      result = ordenes.filter((orden) => {
        return (
          orden.pacienteNombres
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) &&
          orden.estado !== ESTADOS.TERMINADO &&
          orden.estado !== ESTADOS.PENDIENTE
        );
      });
    }

    setFilteredItems(result);
  }, [ordenes, empresaId, query]);
  return [filteredItems, empresaId, setEmpresaId, query, setQuery];
};
export const OrdenesRx = () => {
  const [estado, setEstado] = React.useState({
    loading: true,
    empresas: [],
    empresaId: "",
    ordenes: [],
    error: "",
  });

  const [accion, setAccion] = React.useState("");
  const ordenesRx = useSelector((state) => state.ordenesRx);
  const ordenRx = useSelector((state) => state.ordenRx);
  const sesion = useSelector((state) => state.sesion);
  const [modalTerminar, setModalTerminar] = React.useState(false);
  const [modalVer, setModalVer] = React.useState(false);
  const centroRx = useSelector((state) => state.centroRx);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [ordenesFiltradas, empresaId, setEmpresaId, query, setQuery] =
    useOrdenes(ordenesRx);
  React.useEffect(() => {
    getEmpresas().then((empresas) =>
      setEstado({ ...estado, empresas: empresas })
    );
    dispatch(getOrdenesEmpresa(centroRx.id));
  }, [centroRx]);

  const handleRefresh = () => {
    getEmpresas().then((empresas) =>
      setEstado({ ...estado, empresas: empresas })
    );
    dispatch(getOrdenesEmpresa(centroRx.id));
  };

  const handleOpenModal = (item) => {
    setModalVer(true);
    setModalTerminar(true);
  };

  const handleChange = (e) => {
    setEmpresaId(e.target.value);
    //dispatch(getOrdenesEmpresa(e.target.value));
  };
  const handleCloseModal = (e) => {
    setModalVer(false);
    setModalTerminar(false);
  };

  const handleEvento = (item, evento) => {
    setAccion(evento);
    dispatch(setOrden(item));
    if (evento === EVENTOS.VER_ORDEN) setModalVer(true);
    if (evento === EVENTOS.TERMINAR_ORDEN) setModalTerminar(true);
  };

  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Ordenes Solicitadas</ContainerTitleText>
      </ContainerTitle>
      <FlexContainer>
        <Btn onClick={handleRefresh}>Refrescar</Btn>
      </FlexContainer>
      <ContainerBody>
        <FlexContainer>
          <SelectStyle
            id="empresaId"
            name="empresaId"
            value={empresaId}
            onChange={handleChange}
            onSelected={handleChange}
            required
          >
            <option value="" selected disabled hidden>
              Escoja un consultorio
            </option>
            {estado.empresas.map((empresa) => (
              <option value={empresa.id}>{empresa.razonSocial}</option>
            ))}
          </SelectStyle>
          <ContainerTitleOrden>
            <StyledLabel>Buscar paciente:</StyledLabel>
            <StyledInput
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </ContainerTitleOrden>
        </FlexContainer>

        <ContenedorTabla>
          <OrdenesTable items={ordenesFiltradas} handleEvento={handleEvento} />
        </ContenedorTabla>
      </ContainerBody>
      <Modal isOpen={modalVer} onClose={handleCloseModal}>
        <OrdenRx onSave={handleCloseModal} />
      </Modal>
    </MainContainer>
  );
};
