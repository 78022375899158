import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { auth } from "../../services/firebase/Config";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "../../components/Hooks/useForm";
import { useToasts } from "react-toast-notifications";
import Stardent from "../../img/auspiciadores/stardent.png";
import Digident from "../../img/auspiciadores/digident.png";
import Sysdent from "../../img/sysdent-logo.png";
import {
  LoginViewWrapper,
  FooterWrapper
} from "../Login/styles"

export const ResetPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [{ email }, handleInputChange] = useForm({
    email: "",
  });
  const { addToast } = useToasts();

  const handleForgotPassword = (e) => {
    e.preventDefault()
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        history.push("/login");
      })
      .catch((err) => {
        addToast(err.message, { appearance: "warning" });
      });
  };

  const renderLoginForm = () => {
    return !isLoading ? (
      <div className="form__container">
        <img src={Sysdent} alt="Sysdent logo" />

        <form onSubmit={handleForgotPassword}>
          <fieldset>
            <input
              className="form__input"
              placeholder="Ingrese email"
              name="email"
              onChange={handleInputChange}
            />
          </fieldset>

          <button className="form__button" type="submit">
            Recuperar Contraseña
          </button>
          <Link to="/login">
            <button className="form__button form__button--secondary" type="submit">
              Cancelar
            </button>
          </Link>
        </form>
      </div>
    ) : (
      <>
        <CircularProgress color="secondary" />
      </>
    );
  };
  return (
    <LoginViewWrapper>
      <div className="loginview">
        <section></section>

        {renderLoginForm()}

        <FooterWrapper>
          <footer className="footer">
            <div className="footer__container">
              <h3 className="footer__title">Sponsors:</h3>

              <div className="footer__images__container">
                <img src={Stardent} alt="startdent logo"></img>
                <img src={Digident} alt="digident logo"></img>
              </div>
            </div>
          </footer>
        </FooterWrapper>
      </div>
    </LoginViewWrapper>
  );
};
