import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const DenticionContainer = styled.div`
  display: block;
`;

export const DenticionBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  max-width: 90%;
`;

export const DenticionSide = styled.div`
  display: flex;
  //justify-content: space-between;
  width: 90%;
`;

export const Centrado = styled.div`
  display: flex;
  justify-content: center;
`;
