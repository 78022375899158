import { db } from "../Config";

export const fetchStsOrdenesRx = (centroRxId, year, month) => {
  return new Promise((resolve, reject) => {
    let stsOrdenesRxRef = db.collection("stsOrdenesRx");
    let stsOrdenesRx = [];
    stsOrdenesRxRef
      .where("centroRxId", "==", centroRxId)
      .where("year", "==", year)
      .where("month", "==", month)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          stsOrdenesRx.push(doc.data());
        });
        resolve(stsOrdenesRx);
      })
      .catch((err) => reject(err));
  });
};
