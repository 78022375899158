import { db } from "../Config";
//import { useFirebaseApp } from "reactfire";
// función asincrona de fetch ordenes
//const firebase = useFirebaseApp();
export const fetchTarifario = (centroRxId) => {
  return new Promise((resolve, reject) => {
    let tarifariosCrxRef = db.collection("tarifariosCrx");
    let tarifariosRx;
    tarifariosCrxRef
      .where("centroRxId", "==", centroRxId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          tarifariosRx = doc.data();
        });
        resolve(tarifariosRx);
      })
      .catch((err) => reject(err));
  });
};

export const saveTarifario = (tarifario) => {
  return new Promise((resolve, reject) => {
    const tarifarioCrxRef = db.collection("tarifariosCrx").doc(tarifario.id);
    tarifarioCrxRef
      .set(tarifario)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
