import { MicNoneTwoTone } from "@material-ui/icons";
import { useSelector } from "react-redux";

export const superiorDerecha = "superiorDerecha";
export const inferiorDerecha = "inferiorDerecha";
export const superiorIzquierda = "superiorIzquierda";
export const inferiorIzquierda = "inferiorIzquierda";

export const incisivo_central_sup = "incisivo_central_sup";
export const incisivo_central_inf = "incisivo_central_inf";
export const incisivo_lateral_sup = "incisivo_lateral_sup";
export const incisivo_lateral_inf = "incisivo_lateral_inf";
export const canino_sup = "canino_sup";
export const canino_inf = "canino_inf";
export const premolar_sup = "premolar_sup";
export const premolar_inf = "premolar_inf";
export const molar_sup = "molar_sup";
export const molar_inf = "molar_inf";
export const ADULTO = "ADULTO";
export const INFANTIL = "INFANTIL";
export const molar_deciduo_sup = "molar_deciduo_sup";
export const molar_deciduo_inf = "molar_deciduo_inf";

export const Denticion = {
  dientes: [
    {
      diente: 18,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorDerecha,
      pos: 1,
    },
    {
      diente: 17,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorDerecha,
      pos: 2,
    },
    {
      diente: 16,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorDerecha,
      pos: 3,
    },
    {
      diente: 15,
      tipo: ADULTO,
      nombre: premolar_sup,
      lado: superiorDerecha,
      pos: 4,
    },
    {
      diente: 14,
      tipo: ADULTO,
      nombre: premolar_sup,
      lado: superiorDerecha,
      pos: 5,
    },
    {
      diente: 13,
      tipo: ADULTO,
      nombre: canino_sup,
      lado: superiorDerecha,
      pos: 6,
    },
    {
      diente: 12,
      tipo: ADULTO,
      nombre: incisivo_lateral_sup,
      lado: superiorDerecha,
      pos: 7,
    },
    {
      diente: 11,
      tipo: ADULTO,
      nombre: incisivo_central_sup,
      lado: superiorDerecha,
      pos: 8,
    },
    {
      diente: 21,
      tipo: ADULTO,
      nombre: incisivo_central_sup,
      lado: superiorIzquierda,
      pos: 9,
    },
    {
      diente: 22,
      tipo: ADULTO,
      nombre: incisivo_lateral_sup,
      lado: superiorIzquierda,
      pos: 10,
    },
    {
      diente: 23,
      tipo: ADULTO,
      nombre: canino_sup,
      lado: superiorIzquierda,
      pos: 11,
    },
    {
      diente: 24,
      tipo: ADULTO,
      nombre: premolar_sup,
      lado: superiorIzquierda,
      pos: 12,
    },
    {
      diente: 25,
      tipo: ADULTO,
      nombre: premolar_sup,
      lado: superiorIzquierda,
      pos: 13,
    },
    {
      diente: 26,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorIzquierda,
      pos: 14,
    },
    {
      diente: 27,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorIzquierda,
      pos: 15,
    },
    {
      diente: 28,
      tipo: ADULTO,
      nombre: molar_sup,
      lado: superiorIzquierda,
      pos: 16,
    },
    {
      diente: 31,
      tipo: ADULTO,
      nombre: incisivo_central_inf,
      lado: inferiorIzquierda,
      pos: 25,
    },
    {
      diente: 32,
      tipo: ADULTO,
      nombre: incisivo_lateral_inf,
      lado: inferiorIzquierda,
      pos: 26,
    },
    {
      diente: 33,
      tipo: ADULTO,
      nombre: canino_inf,
      lado: inferiorIzquierda,
      pos: 27,
    },
    {
      diente: 34,
      tipo: ADULTO,
      nombre: premolar_inf,
      lado: inferiorIzquierda,
      pos: 28,
    },
    {
      diente: 35,
      tipo: ADULTO,
      nombre: premolar_inf,
      lado: inferiorIzquierda,
      pos: 29,
    },
    {
      diente: 36,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorIzquierda,
      pos: 30,
    },
    {
      diente: 37,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorIzquierda,
      pos: 31,
    },
    {
      diente: 38,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorIzquierda,
      pos: 32,
    },
    {
      diente: 48,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorDerecha,
      pos: 17,
    },
    {
      diente: 47,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorDerecha,
      pos: 18,
    },
    {
      diente: 46,
      tipo: ADULTO,
      nombre: molar_inf,
      lado: inferiorDerecha,
      pos: 19,
    },
    {
      diente: 45,
      tipo: ADULTO,
      nombre: premolar_inf,
      lado: inferiorDerecha,
      pos: 20,
    },
    {
      diente: 44,
      tipo: ADULTO,
      nombre: premolar_inf,
      lado: inferiorDerecha,
      pos: 21,
    },
    {
      diente: 43,
      tipo: ADULTO,
      nombre: canino_inf,
      lado: inferiorDerecha,
      pos: 22,
    },
    {
      diente: 42,
      tipo: ADULTO,
      nombre: incisivo_lateral_inf,
      lado: inferiorDerecha,
      pos: 23,
    },
    {
      diente: 41,
      tipo: ADULTO,
      nombre: incisivo_central_inf,
      lado: inferiorDerecha,
      pos: 24,
    },

    {
      diente: 55,
      tipo: INFANTIL,
      nombre: molar_deciduo_sup,
      lado: superiorDerecha,
      pos: 1,
    },
    {
      diente: 54,
      tipo: INFANTIL,
      nombre: molar_deciduo_sup,
      lado: superiorDerecha,
      pos: 2,
    },
    {
      diente: 53,
      tipo: INFANTIL,
      nombre: canino_sup,
      lado: superiorDerecha,
      pos: 3,
    },
    {
      diente: 52,
      tipo: INFANTIL,
      nombre: incisivo_lateral_sup,
      lado: superiorDerecha,
      pos: 4,
    },
    {
      diente: 51,
      tipo: INFANTIL,
      nombre: incisivo_central_sup,
      lado: superiorDerecha,
      pos: 5,
    },
    {
      diente: 61,
      tipo: INFANTIL,
      nombre: incisivo_central_sup,
      lado: superiorIzquierda,
      pos: 6,
    },
    {
      diente: 62,
      tipo: INFANTIL,
      nombre: incisivo_lateral_sup,
      lado: superiorIzquierda,
      pos: 7,
    },
    {
      diente: 63,
      tipo: INFANTIL,
      nombre: canino_sup,
      lado: superiorIzquierda,
      pos: 8,
    },
    {
      diente: 64,
      tipo: INFANTIL,
      nombre: molar_deciduo_sup,
      lado: superiorIzquierda,
      pos: 9,
    },
    {
      diente: 65,
      tipo: INFANTIL,
      nombre: molar_deciduo_sup,
      lado: superiorIzquierda,
      pos: 10,
    },
    {
      diente: 71,
      tipo: INFANTIL,
      nombre: incisivo_central_inf,
      lado: inferiorIzquierda,
      pos: 16,
    },
    {
      diente: 72,
      tipo: INFANTIL,
      nombre: incisivo_lateral_inf,
      lado: inferiorIzquierda,
      pos: 17,
    },
    {
      diente: 73,
      tipo: INFANTIL,
      nombre: canino_inf,
      lado: inferiorIzquierda,
      pos: 18,
    },
    {
      diente: 74,
      tipo: INFANTIL,
      nombre: molar_deciduo_inf,
      lado: inferiorIzquierda,
      pos: 19,
    },
    {
      diente: 75,
      tipo: INFANTIL,
      nombre: molar_deciduo_inf,
      lado: inferiorIzquierda,
      pos: 20,
    },
    {
      diente: 85,
      tipo: INFANTIL,
      nombre: molar_deciduo_inf,
      lado: inferiorDerecha,
      pos: 11,
    },
    {
      diente: 84,
      tipo: INFANTIL,
      nombre: molar_deciduo_inf,
      lado: inferiorDerecha,
      pos: 12,
    },
    {
      diente: 83,
      tipo: INFANTIL,
      nombre: canino_inf,
      lado: inferiorDerecha,
      pos: 13,
    },
    {
      diente: 82,
      tipo: INFANTIL,
      nombre: incisivo_lateral_inf,
      lado: inferiorDerecha,
      pos: 14,
    },
    {
      diente: 81,
      tipo: INFANTIL,
      nombre: incisivo_central_inf,
      lado: inferiorDerecha,
      pos: 15,
    },
  ],

  getDientes(tipo, lado) {
    if (!tipo) return [];
    return this.dientes.filter(
      (item) => item.lado === lado && item.tipo == tipo
    );
  },
  getTipo(diente) {
    if (!diente) return "";
    const idx = this.dientes.findIndex((item) => {
      return item.diente == diente;
    });
    return this.dientes[idx].tipo;
  },
  getNombre(diente) {
    if (!diente) return "";
    const idx = this.dientes.findIndex((item) => {
      return item.diente == diente;
    });
    return this.dientes[idx].nombre;
  },
};

const red = "#C64832";
const blue = "#002E82";
const white = "#ffff";
const celeste = "#76AADB";
const verde = "#336535";
const verdeLimon = "#A3BD31";
const violeta = "#72408d";
const beige = "#ffeca2";
const beigeOscuro = "#ffd761";
const celesteOscuro = "#a7bcff";
const celesteClaro = "#d4e7ff";
//const celeste = "#d4e7ff";

export class DefaultConfig {
  constructor(diente) {
    this.diente = diente;
    this.estado = "NORMAL";
    this.nucleo = {
      strokeColor: "none",
      fill: "none",
    };
    this.raiz = {
      strokeColor: beigeOscuro,
      fill: beige,
    };
    this.corona = {
      strokeColor: celesteOscuro,
      fill: celesteClaro,
    };
    this.implante = {
      strokeColor: "none",
      fill: "none",
    };
    this.caras = [
      { cara: 6, color: "none", strokeColor: "none" },
      { cara: 7, color: "none", strokeColor: "none" },
      { cara: 8, color: "none", strokeColor: "none" },
      { cara: 9, color: "none", strokeColor: "none" },
    ];
  }
}
export const EXODONCIA = "EXODONCIA";
export const IMPLANTE = "IMPLANTE";
export const CARAS = "CARAS";
export const CORONA = "CORONA";
export const NUCLEO = "NUCLEO";

export const getCarasProtesis = ({ codtra, color }) => {
  const arr = [1, 2, 3, 4, 5].map((it) => ({
    codtra: codtra,
    cara: it,
    color: color,
  }));
  return arr;
};
export const getDefaultConfig = (diente) => {
  return new DefaultConfig(diente);
};

export const unirConfiguracion = (itemOdonto, prestacion) => {
  if (!itemOdonto || !prestacion) return new DefaultConfig(11);
  //const itemOdonto = JSON.parse(JSON.stringify(itemOdonto));
  let { color, grupo } = prestacion.config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };

  if (grupo === EXODONCIA || grupo === IMPLANTE) {
    itemOdonto.estado = grupo;
    itemOdonto.corona = itemVacio;
    itemOdonto.raiz = itemVacio;
    itemOdonto.nucleo = itemVacio;
    itemOdonto.implante = itemVacio;
    itemOdonto.caras = [
      { cara: 1, color: "none", strokeColor: "none" },
      { cara: 2, color: "none", strokeColor: "none" },
      { cara: 3, color: "none", strokeColor: "none" },
      { cara: 4, color: "none", strokeColor: "none" },
      { cara: 5, color: "none", strokeColor: "none" },
      { cara: 6, color: "none", strokeColor: "none" },
      { cara: 7, color: "none", strokeColor: "none" },
      { cara: 8, color: "none", strokeColor: "none" },
      { cara: 9, color: "none", strokeColor: "none" },
    ];
  }
  if (grupo === IMPLANTE) {
    itemOdonto.implante = {
      fill: color,
      strokeColor: "none",
    };
  }
  switch (grupo) {
    case NUCLEO:
      itemOdonto.nucleo = {
        fill: color,
        strokeColor: "none",
      };
      break;
    case CORONA:
      itemOdonto.corona = {
        fill: color,
        strokeColor: "none",
      };
      prestacion.caras.forEach((cara) => {
        const idx = itemOdonto.caras.findIndex((it) => it.cara == cara);
        if (idx > -1) {
          itemOdonto.caras[idx] = {
            cara: cara,
            color: color,
          };
        } else {
          itemOdonto.caras.push({
            cara: cara,
            color: color,
          });
        }
      });

      break;
    case CARAS:
      {
        // recorre cada cara de la prestacion si existe la reemplaza
        prestacion.caras.forEach((cara) => {
          const idx = itemOdonto.caras.findIndex((it) => it.cara == cara);
          if (idx > -1) {
            itemOdonto.caras[idx] = {
              cara: cara,
              color: color,
            };
          } else {
            itemOdonto.caras.push({
              cara: cara,
              color: color,
            });
          }
        });
      }
      break;
  }

  return itemOdonto;
};

export const aplicarCorona = (itemOdonto, config, caras, esPilar) => {
  let { color, grupo, coronaTipo } = config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };
  itemOdonto.corona = {
    fill: color,
    strokeColor: "none",
  };
  caras.forEach((cara) => {
    const idx = itemOdonto.caras.findIndex((it) => it.cara == cara);
    if (idx > -1) {
      itemOdonto.caras[idx] = {
        cara: cara,
        color: color,
      };
    } else {
      itemOdonto.caras.push({
        cara: cara,
        color: color,
      });
    }
  });
  if (coronaTipo == "PROTESIS_REMOVIBLE") {
    itemOdonto.raiz = itemVacio;
    itemOdonto.nucleo = itemVacio;
  }
  if (coronaTipo == "PUENTE") {
    if (!esPilar) {
      itemOdonto.raiz = itemVacio;
      itemOdonto.nucleo = itemVacio;
    }
  }
  return itemOdonto;
};

export const aplicarCaras = (itemOdonto, config, caras) => {
  let { color, grupo, coronaTipo } = config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };

  // recorre cada cara de la prestacion si existe la reemplaza
  caras.forEach((cara) => {
    const idx = itemOdonto.caras.findIndex((it) => it.cara == cara);
    if (idx > -1) {
      itemOdonto.caras[idx] = {
        cara: cara,
        color: color,
      };
    } else {
      itemOdonto.caras.push({
        cara: cara,
        color: color,
      });
    }
  });
  return itemOdonto;
};
export const aplicarPrestacion = ({ itemOdonto, config, caras, esPilar }) => {
  const { grupo } = config;

  switch (grupo) {
    case "CARAS":
      return aplicarCaras(itemOdonto, config, caras);
    case "NUCLEO":
      return aplicarNucleo(itemOdonto, config);
    case "CORONA":
      return aplicarCorona(itemOdonto, config, caras, esPilar);

    case "IMPLANTE":
      return aplicarImplante(itemOdonto, config);
    case "EXODONCIA":
      return aplicarExodoncia(itemOdonto, config);
    default:
      throw new Error("Grupo no configurado");
  }
};
export const aplicarExodoncia = (itemOdonto, config) => {
  let { color, grupo, coronaTipo } = config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };
  itemOdonto.estado = grupo;
  itemOdonto.corona = itemVacio;
  itemOdonto.raiz = itemVacio;
  itemOdonto.nucleo = itemVacio;
  itemOdonto.implante = itemVacio;
  itemOdonto.caras = [
    { cara: 1, color: "none", strokeColor: "none" },
    { cara: 2, color: "none", strokeColor: "none" },
    { cara: 3, color: "none", strokeColor: "none" },
    { cara: 4, color: "none", strokeColor: "none" },
    { cara: 5, color: "none", strokeColor: "none" },
    { cara: 6, color: "none", strokeColor: "none" },
    { cara: 7, color: "none", strokeColor: "none" },
    { cara: 8, color: "none", strokeColor: "none" },
    { cara: 9, color: "none", strokeColor: "none" },
  ];

  return itemOdonto;
};

export const aplicarImplante = (itemOdonto, config) => {
  let { color, grupo, coronaTipo } = config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };
  itemOdonto.estado = grupo;
  itemOdonto.corona = itemVacio;
  itemOdonto.raiz = itemVacio;
  itemOdonto.nucleo = itemVacio;
  itemOdonto.implante = {
    fill: color,
    strokeColor: "none",
  };
  itemOdonto.caras = [
    { cara: 1, color: "none", strokeColor: "none" },
    { cara: 2, color: "none", strokeColor: "none" },
    { cara: 3, color: "none", strokeColor: "none" },
    { cara: 4, color: "none", strokeColor: "none" },
    { cara: 5, color: "none", strokeColor: "none" },
    { cara: 6, color: "none", strokeColor: "none" },
    { cara: 7, color: "none", strokeColor: "none" },
    { cara: 8, color: "none", strokeColor: "none" },
    { cara: 9, color: "none", strokeColor: "none" },
  ];

  return itemOdonto;
};

export const aplicarNucleo = (itemOdonto, config) => {
  let { color, grupo, coronaTipo } = config;
  if (color === undefined || grupo === undefined)
    throw new Error("Prestación no está configurada adecuadamente");

  let itemVacio = {
    fill: "none",
    strokeColor: "none",
  };
  itemOdonto.estado = grupo;
  itemOdonto.nucleo = {
    fill: color,
    strokeColor: "none",
  };
  itemOdonto.implante = itemVacio;

  return itemOdonto;
};
