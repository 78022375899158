import React from "react";
import {
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DienteContenedorCorona,
  DienteContenedorRaiz,
  Img,
} from "../Styles/dientes.css";

export const DienteSuperior = ({ item, onSelectedTooth, checked }) => {
  const { diente, nombre } = item;
  const imgDiente = require(`../svg/${nombre}.svg`);
  const imgCorona = require(`../svg/incisivo.svg`);
  //console.log(rutaDiente);

  return (
    <DienteContenedor>
      <DienteContenedorRaiz>
        <Img src={imgDiente} />
      </DienteContenedorRaiz>
      <ContenedorAuxiliar>
        <DienteContenedorCorona>{diente}</DienteContenedorCorona>

        <DienteContenedorNumero>
          <input type="checkbox" id={diente} name="dientes" checked={checked} />
        </DienteContenedorNumero>
      </ContenedorAuxiliar>
    </DienteContenedor>
  );
};
