import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;
export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
`;
export const ContainerBody = styled.div`
  width: 95%;
  height: 100%;
  padding: 3%;
  margin: 3%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  @media ${dispositivo.laptop} {
    padding: 3% 10%;
  }

`;

export const Tabla = styled.table`
  width: 100%;
`;
export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;
export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
  }
`;
export const TablaBodyTexto = styled.td`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const StyleInput = styled.input`
    width: 65%;
    font-size: 0.875rem;
    text-align: end;
    padding-right: 0.5em;
    border-radius: 5px;
    border: 1px solid #045184;
    font-weight: bold;
    &::placeholder {
        color: #04000060;
        fonst-size: 0.5rem;
        }
        &:focus{
        border: 2px solid #0060b0;
        }
    @media ${dispositivo.mobileL} {
    width: 20%;
    font-size: 0.875rem;
    }
    @media ${dispositivo.tablet} {
    font-size: 1rem;
    }
`;
export const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
`;
export const Button = styled.input`
    height: 2.5rem;
    font-size: 0.9rem;
    width: 15rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    outline: none;
    &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
    }
    @media ${dispositivo.mobileM} {
    width: 15rem;
    font-size: 1rem;
    }
    @media ${dispositivo.laptop} {
    width: 15rem;
    }
`;

