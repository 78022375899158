import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  MainContainer,
  ContainerBody,
  ContainerTitleText,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  ContainerButton,
  Button,
} from "./styles";

export const Tarifario = () => {
  const tarifario = useSelector((state) => state.tarifario);

  return (
    <MainContainer>
      <form>
        <fieldset>
          <ContainerTitleText>
            <legend>Tarifario</legend>
          </ContainerTitleText>
          <ContainerBody>
            <Tabla>
              <TablaHeader>
                <tr>
                  <TablaHeaderTexto>Grupo</TablaHeaderTexto>
                  <TablaHeaderTexto>Tipo</TablaHeaderTexto>
                  <TablaHeaderTexto>Comisión</TablaHeaderTexto>
                </tr>
              </TablaHeader>
              <TablaBody>
                {tarifario.items.map((item, index) => (
                  <tr>
                    <TablaBodyTexto>{item.grupo}</TablaBodyTexto>
                    <TablaBodyTexto>{item.llave}</TablaBodyTexto>
                    <TablaBodyTexto>{item.valor}</TablaBodyTexto>
                  </tr>
                ))}
              </TablaBody>
            </Tabla>
          </ContainerBody>
        </fieldset>
      </form>
    </MainContainer>
  );
};
