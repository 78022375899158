import { db } from "../Config";

export const getEmpresaById = (empresaId) => {
  let empresaRef = db.collection("empresas").doc(empresaId);
  return new Promise((resolve, reject) => {
    let empresa = {};
    empresaRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          empresa = {
            ruc: doc.data().ruc,
            id: doc.data().id,
            razonSocial: doc.data().razonSocial,
            telefono: doc.data().telefono,
            googleCalendar: doc.data().googleCalendar,
            logoUrl: doc.data().logoUrl,
          };
          resolve(empresa);
        } else {
          reject("no encontro la empresa");
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getEmpresa = (empresaId) => {
  let empresa;
  let empresaRef = db.collection("empresas").doc(empresaId);
  empresaRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        empresa = {
          RUC: doc.data().RUC,
          codigo: doc.data().codigo,
          id: doc.data().id,
          logoNombre: doc.data().logoNombre,
          razonSocial: doc.data().razonSocial,
          telefono: doc.data().telefono,
        };
      } else {
        console.log("no encontro la empresa");
      }
    })
    .catch((err) => {});
};

export const getEmpresas = () => {
  return new Promise((resolve, reject) => {
    let empresas = [];

    let empresasRef = db.collection("empresas");
    empresasRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          empresas.push(doc.data());
        });
        resolve(empresas);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
