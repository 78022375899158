import React, { useContext, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import Layout from "./Layout";
import { AuthProvider, AuthContext } from "./Auth";
import NotFound from "./NotFound";
import Login from "../pages/Login";
import { ResetPassword } from "../pages/ResetPassword";
import { Register } from "../pages/Register/index";
import { useDispatch, useSelector } from "react-redux";
import { setFechaSesion } from "../redux/sesion/sesionActions";
import { GlobalStyle } from "../GlobalStyles";
import { PrivateRoute } from "./PrivateRoute";
import { fetchUsuario } from "../services/firebase/usuarios";
import { getCentroRx } from "../redux/centroRx/centroRxActions";
import { getTarifario } from "../redux/tarifario/tarifarioActions";
import { OrdenesRx } from "../pages/OrdenesRx";
import { OrdAtendidasRes } from "../pages/OrdAtendidasRes";
import { Empresa } from "../pages/Empresa";
import { Tarifario } from "../pages/Tarifario";
import { LayoutPaciente } from "./LayoutPaciente/index";

import {
  setUsuarioSesion,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../redux/sesion/sesionActions";
import { auth } from "../services/firebase/Config";
import LogoWaterMark from "./LogoWaterMark/";

const getFecha = () => {
  const fecha = new Date();
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

const MainApp = () => {
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  let { path, url } = useRouteMatch();
  const getUsuario = async (email) => {
    try {
      dispatch(httpRequest());
      let usuario = await fetchUsuario(email);
      dispatch(setUsuarioSesion(usuario));
      dispatch(getCentroRx(usuario.centroRxId));
      dispatch(getTarifario(usuario.centroRxId));
      dispatch(httpSuccess());
      setUsuario(usuario);
    } catch (err) {
      dispatch(httpFailure());
      throw err;
    }
  };

  React.useEffect(() => {
    if (currentUser) getUsuario(currentUser.email);
    else getUsuario(auth.currentUser.email);
  }, []);

  return (
    <BrowserRouter>
      <LayoutPaciente url={url} path={path}>
        <Switch>
          <PrivateRoute
            exact
            path={`/ordenes/pendientes`}
            component={OrdenesRx}
          />
          <PrivateRoute
            exact
            path={`/ordenes/atendidas`}
            component={OrdAtendidasRes}
          />
          <PrivateRoute exact path={`/admin/tarifario`} component={Tarifario} />

          <PrivateRoute exact path={`/admin/empresa`} component={Empresa} />

          <Route component={NotFound}></Route>
        </Switch>
        <LogoWaterMark />
      </LayoutPaciente>
    </BrowserRouter>
  );
};

function App() {
  const dispatch = useDispatch();

  dispatch(setFechaSesion(getFecha()));

  return (
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Layout>
          <Switch>
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <PrivateRoute path="/" component={MainApp} />
            <Route component={<h1>Ingreso aqui</h1>}></Route>
          </Switch>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
