import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const DienteContenedor = styled.div`
  display: block;
  max-width: 100px;
  //width: 50%;
  margin: 1px;
  @media ${dispositivo.tablet} {
    width: 80%;
    margin: 2px;
  }
  @media ${dispositivo.laptop} {
    width: 100%;
    margin: 4px;
  }
`;

export const BackContainer = styled.div`
  width: auto;
  display: flex;
  padding: 3px;

  @media ${dispositivo.tablet} {
    padding: 10px;
  }
`;

export const DienteContenedorRaiz = styled.div`
  display: block;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 0px;
  max-width: 25px;
  justify-content: center;
  max-height: 50px;

  @media ${dispositivo.mobileL} {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  @media ${dispositivo.tablet} {
    height: 50px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  @media ${dispositivo.laptop} {
    height: 100px;
    margin-top: 7px;
    margin-bottom: 0px;
  }
`;

export const DienteContenedorCorona = styled.div`
  justify-content: center;
  width: 80%;
  width: 60%;
  font-size: 11px;
  @media ${dispositivo.tablet} {
    width: 100%;
    font-size: 14px;
  }
`;

export const DienteContenedorNumero = styled.div`
  display: block;
  width: 60%;
  justify-content: center;
  @media ${dispositivo.tablet} {
    width: 80%;
  }
`;

export const ContenedorAuxiliar = styled.div`
  justify-content: center;
  width: 60%;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;

export const Img = styled.img`
  height: 80%;

  @media ${dispositivo.mobileL} {
    height: 90%;
  }

  @media ${dispositivo.tablet} {
    height: 100%;
  }
`;
