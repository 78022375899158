import React from "react";
import { CheckboxText } from "../../components/CheckboxText/index";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerCheckBoxes,
  TituloCheckBoxes,
  SubTitulo,
  Button,
  Strong,
  ContainerFlex,
  HeaderContainer,
  HeaderContainerDiv,
  ButtonContainerDiv,
  Input,
} from "../../components/Styles/formularios.css";
import { DientesCheck } from "../../components/DientesCheck";
import {
  LeftFlowContainer,
  FlowContainer,
} from "../../components/Styles/contenedores.css";
import { useToasts } from "react-toast-notifications";
import {
  actualizaOrdenRx,
  atenderOrdenRx,
  setOrden,
} from "../../redux/ordenRx/ordenRxActions";
import { useDispatch, useSelector } from "react-redux";
const INTRAORAL = "INTRAORAL";
const PERIAPICAL = "PERIAPICAL";
const BITEWING = "BITEWING";
const OCLUSAL = "OCLUSAL";
const EXTRAORALES = "EXTRAORALES";
const ANACOMP1 = "ANACOMP1";
const ANACOMP2 = "ANACOMP2";
const FOTOS = "FOTOS";
const TOMOGRAFIA = "TOMOGRAFIA";
const RXLATERAL = "RXLATERAL";
const RXFRONTAL = "RXFRONTAL";

const secciones = [
  {
    titulo: "RADIOGRAFÍAS INTRAORALES",
    grupos: [PERIAPICAL, BITEWING, OCLUSAL],
  },
  {
    titulo: "RADIOGRAFÍAS EXTRAORALES",
    grupos: [EXTRAORALES],
  },

  {
    titulo: "ANÁLISIS COMPUTARIZADO",
    grupos: [RXLATERAL, RXFRONTAL],
  },
  {
    titulo: "FOTOS",
    grupos: [FOTOS],
  },
  {
    titulo: "TOMOGRAFÍA",
    grupos: [TOMOGRAFIA],
  },
];

const hayItemsGrupo = (items, grupo) => {
  return items.some((item) => item.grupo === grupo);
};

const hayItemsSeccion = (items, grupos) => {
  return items.some((item) => grupos.includes(item.grupo));
};
const Seccion = ({ titulo, grupos, items, onChange, paciente }) => {
  if (!hayItemsSeccion(items, grupos)) return null;
  return (
    <ContainerCheckBoxes>
      <TituloCheckBoxes>{titulo}</TituloCheckBoxes>

      {grupos.map((grupo) => (
        <Grupo
          items={items.filter((item) => item.grupo === grupo)}
          grupo={grupo}
          onChange={onChange}
        />
      ))}
    </ContainerCheckBoxes>
  );
};

const Grupo = ({ items, grupo, onChange }) => {
  if (!hayItemsGrupo(items, grupo)) return null;
  return (
    <div>
      <SubTitulo>{grupo}</SubTitulo>
      <FlowContainer>
        {items.map((item) => (
          <Elemento item={item} onChange={onChange} />
        ))}
      </FlowContainer>
      {grupo === PERIAPICAL ? <DientesCheck llave="periapical" /> : null}
    </div>
  );
};

const Elemento = ({ item, onChange }) => {
  return (
    <label>
      {item.descripcion}
      <input
        name={item.llave}
        type="checkbox"
        checked={item.checked}
        value={item.llave}
        onChange={(e) => onChange(e, item)}
      />
    </label>
  );
};
export const OrdenRx = (props) => {
  const ordenRx = useSelector((state) => state.ordenRx);

  const dispatch = useDispatch();
  const tarifario = useSelector((state) => state.tarifario);
  const { addToast } = useToasts();

  const handleChange = (e, item) => {
    let miOrden = { ...ordenRx };
    let idx = miOrden.items.findIndex((it) => it.llave === item.llave);
    miOrden.items[idx].checked = e.target.checked;
    dispatch(setOrden(miOrden));
  };
  const valida = (items) => {};

  const handleSave = () => {
    try {
      if (!ordenRx.items.some((it) => it.checked))
        throw new Error("Selecciona un item");
      if (!ordenRx.items.some((it) => it.checked === false))
        ordenRx.estado = "ATENDIDO";
      // cada item se guarda como atendido

      ordenRx.fecTer = new Date();
      dispatch(atenderOrdenRx(ordenRx, tarifario));
      props.onSave();
      addToast("Grabación satisfactoria", { appearance: "success" });
    } catch (e) {
      addToast(`Ocurrió un error ${e.message}`, { appearance: "error" });
    }
  };

  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Orden Rx</ContainerTitleText>
      </ContainerTitle>
      <HeaderContainer>
        <HeaderContainerDiv>
          <span>
            <Strong>Nombres: </Strong>
            {`${ordenRx.paciente?.apellidos} ${ordenRx.paciente?.nombres}`}
          </span>
        </HeaderContainerDiv>
        <HeaderContainerDiv>
          <span>
            <Strong>Fec.Nac: </Strong>
            {`${ordenRx.paciente?.fecNac}`}
          </span>
        </HeaderContainerDiv>
        <HeaderContainerDiv>
          <span>
            <Strong>Tel: </Strong>
            {`${ordenRx.paciente?.telefono}`}
          </span>
        </HeaderContainerDiv>
      </HeaderContainer>

      <LeftFlowContainer>
        <Strong>Comentarios:</Strong>
        <span>{ordenRx.comentarios}</span>
      </LeftFlowContainer>
      <ButtonContainerDiv>
        {props.accion !== "VER" ? (
          <Button
            variant="contained"
            size="large"
            color="#dc3545"
            onClick={handleSave}
          >
            Guardar como Atendido
          </Button>
        ) : null}
      </ButtonContainerDiv>
      {secciones.map((seccion) => (
        <Seccion
          titulo={seccion.titulo}
          grupos={seccion.grupos}
          items={ordenRx.items.filter((it) => !it.atendido)}
          onChange={handleChange}
        />
      ))}
    </MainContainer>
  );
};
