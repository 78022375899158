import { combineReducers } from "redux";
import { sesionReducers } from "./sesion/sesionReducers";
import { ordenRxReducers } from "./ordenRx/ordenRxReducers";
import { ordenesRxReducers } from "./ordenesRx/ordenesRxReducers";
import { centroRxReducers } from "./centroRx/centroRxReducers";
import { stsOrdenesRxReducers } from "./stsOrdenesRx/stsOrdenesRxReducers";
import { tarifarioReducers } from "./tarifario/tarifarioReducers";
const allReducers = combineReducers({
  // caras: prestacionReducer,
  sesion: sesionReducers,
  ordenRx: ordenRxReducers,
  ordenesRx: ordenesRxReducers,
  centroRx: centroRxReducers,
  stsOrdenesRx: stsOrdenesRxReducers,
  tarifario: tarifarioReducers,
});

export default allReducers;
