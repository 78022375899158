import { db, firestorage } from "../Config";
import { COrden } from "../../../Entidades/Orden";
import firebase from "firebase/app";
// función asincrona de fetch ordenes
export const fetchOrdenesRx = () => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenesRx");
    let ordenes = [];
    ordenesRef
      .where("estado", "in", ["SOLICITADO"])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecTer: doc.data().fecTer,
            centroRxId: doc.data().centroRxId,
            centroRxNombre: doc.data().centroRxNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesRxEmpresa = (empresaId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenesRx");
    let ordenes = [];
    ordenesRef
      .where("centroRxId", "==", empresaId)
      .where("estado", "in", ["SOLICITADO"])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv.toDate(),
            fecTer: doc.data().fecTer,
            centroRxId: doc.data().centroRxId,
            centroRxNombre: doc.data().centroRxNombre,
            pacienteId: doc.data().pacienteId,
            paciente: doc.data().paciente,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            comentarios: doc.data().comentarios,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesAtendidasEmpresa = (empresaId, fecIni, fecFin) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenesRx");
    let ordenes = [];
    ordenesRef
      .where("centroRxId", "==", empresaId)
      .where("fecTer", ">=", fecIni)
      .where("fecTer", "<=", fecFin)
      .where("estado", "==", "ATENDIDO")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv.toDate(),
            fecTer: doc.data().fecTer.toDate(),
            centroRxId: doc.data().centroRxId,
            centroRxNombre: doc.data().centroRxNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            comentarios: doc.data().comentarios,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesRxPaciente = (pacienteId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenesRx");
    let ordenes = [];
    ordenesRef
      .where("pacienteId", "==", pacienteId)
      .where("estado", "in", ["PENDIENTE", "ENVIADO"])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecTer: doc.data().fecTer,
            centroRxId: doc.data().centroRxId,
            centroRxNombre: doc.data().centroRxNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            comentarios: doc.data().comentarios,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const getOrden = (ordenId) => {
  let orden;
  let ordenRef = db.collection("ordenesRx").doc(ordenId);
  ordenRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log("se recupero el documento", doc.data());
        orden = {
          id: doc.data().id,
          estado: doc.data().estado,
          fecEnv: doc.data().fecEnv,
          fecTer: doc.data().fecTer,
          centroRxId: doc.data().centroRxId,
          centroRxNombre: doc.data().centroRxNombre,
          pacienteId: doc.data().pacienteId,
          pacienteNombres: doc.data().pacienteNombres,
          empresaId: doc.data().empresaId,
          items: doc.data().items,
          empresa: doc.data().empresa,
          comentarios: doc.data().comentarios,
        };
        console.log(orden);
      } else {
        console.log("no encontro la orden");
        orden = new COrden();
      }
    })
    .catch((err) => {});
};

var ordenConverter = {
  toFirestore: function (orden) {
    return {
      id: orden.id,
      estado: orden.estado,
      fecEnv: orden.fecEnv,
      fecTer: orden.fecTer,
      centroRxId: orden.labId,
      centroRxNombre: orden.labNombre,
      pacienteId: orden.pacienteId,
      pacienteNombres: orden.pacienteNombres,
      prestacionNombre: orden.prestacionNombre,
      estFin: orden.estFin,
      numCor: orden.numCor,
      empresa: orden.empresa,
      comentarios: orden.comentarios,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: data().id,
      estado: data().estado,
      fecEnv: data().fecEnv,
      fecTer: data().fecTer,
      centroRxId: data().centroRxId,
      centroRxNombre: data().centroRxNombre,
      pacienteId: data().pacienteId,
      pacienteNombres: data().pacienteNombres,
      empresaId: data().empresaId,
      items: data().items,
      empresa: data().empresa,
      comentarios: data().comentarios,
    };
  },
  toDate(fecha) {
    if (typeof fecha === "string") return new Date(fecha);
  },
  toCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  },
};
