import React from "react";
import {
  RegistroWrapper,
  RegistroFormWrapper,
  ContainerTitle,
  ContainerTitleText,
  StyledForm,
  ContainerLeft,
  StyledButton,
  DivInput,
  StyledLabel,
  StyledInput,
} from "./styles";
import {
  setCentroRx,
  guardaCentroRx,
} from "../../redux/centroRx/centroRxActions";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { uploadImage } from "../../services/firebase/centrosRx/index";
export const Empresa = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const centroRx = useSelector((state) => state.centroRx);
  const sesion = useSelector((state) => state.sesion);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(guardaCentroRx(centroRx));
      addToast("Empresa guardada satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleChange = (e) => {
    dispatch(setCentroRx({ ...centroRx, [e.target.name]: e.target.value }));
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const fileUrl = await uploadImage(file);
    dispatch(setCentroRx({ ...centroRx, logoUrl: fileUrl }));
  };
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <RegistroWrapper>
      <ContainerTitle>
        <ContainerTitleText>Datos Empresa</ContainerTitleText>
      </ContainerTitle>
      <RegistroFormWrapper>
        <form onSubmit={handleSubmit}>
          <ContainerLeft>
            <StyledButton className="form__button" type="submit">
              Guardar
            </StyledButton>
          </ContainerLeft>
          <StyledForm>
            <DivInput>
              <StyledLabel>Razon Social</StyledLabel>
              <StyledInput
                name="razonSocial"
                className="form__input"
                placeholder="Razon Social"
                type="text"
                value={centroRx.razonSocial}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>RUC</StyledLabel>
              <StyledInput
                name="ruc"
                className="form__input"
                placeholder="RUC"
                type="text"
                value={centroRx.ruc}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Email</StyledLabel>
              <StyledInput
                name="email"
                className="form__input"
                placeholder="Google Calendar"
                type="text"
                value={centroRx.email}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Seleccione</StyledLabel>

              <div className="button-wrapper">
                <span className="label">{<AddPhotoAlternateIcon />} Logo</span>

                <input
                  type="file"
                  className="upload-box"
                  onChange={onFileChange}
                />
              </div>
            </DivInput>
          </StyledForm>
        </form>
      </RegistroFormWrapper>
    </RegistroWrapper>
  );
};
