import styled from "styled-components";
import { dispositivo } from "../Dispositivos";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

export const MainContainer = styled.div`
  width: 90%;
  height: 100%;
  padding-left: 15%;
  padding-right: 15%;
  margin-left: 40px;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;
export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;
export const Button = styled.button`
  background-color: ${(props) => props.color || "#0c8de3"};
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
`;
export const SectionTarjeta = styled.section`
  justify-content: center;
  margin-left: 7%;

  @media ${dispositivo.tablet} {
    flex: 1;
    max-height: 370px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;

export const ContenedorTarjeta = styled(Card)`
  display: block;
  padding: 5px;
  max-width: 400px;
  max-height: 400px;
  width: 80%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const ContainerCheckBoxes = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 3%;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding: 4px;
  font-size: 12px;

  border-radius: 10px;
  border: 2px solid #0c8de3;

  @media ${dispositivo.mobileL} {
    margin-left: 1%;
    margin-right: 1%;
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 15px;

    margin-left: 17px;
    margin-right: 17px;
  }
`;

export const MyCheck = styled.input`
  background: blue;
  :checked {
    border: 1px solid blue;
    background: blue;
  }
`;

export const TituloCheckBoxes = styled.h2`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  color: #0c8de3;
`;

export const SubTitulo = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  margin-top: 5px;
  color: #0c8de3;
`;

export const Strong = styled.strong`
  font-weight: bold;
  text-align: left;
  color: #0c8de3;
`;

export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #707070;
  background: #fff;
  padding: 2% !important;
`;

export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
  color: #707070;
`;

export const TarjetaAccion = styled(CardActions)`
  justify-content: center;
`;
export const Icono = styled.div`
  margin: 0%;
  cursor: pointer;
`;
export const Table = styled.table`
  width: 100%;
`;

export const Thead = styled.thead`
  background: #fff;
  padding-left: 1px;
  padding-right: 7px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
`;

export const Td = styled.td`
  width: 60%;
`;
export const Th = styled.th`
  font-size: 12px;
  padding-left: 1%;
  width: 20%;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5%;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 15px;
  }
`;

export const Tbody = styled.div`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-top: 2%;
  margin-right: 5%;
  margin-bottom: 5%;
`;

export const Input = styled.input`
  font-size: 12px;
  width: 90%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  padding: 0.5%;
  border: 2px solid #0c8de3;

  border-radius: 5px;

  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    width: 80%;
    font-size: 18px;
  }
`;

export const Select = styled.select`
  width: 90%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  border: 2px solid #0c8de3;
  font-size: 12px;

  border-radius: 5px;

  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    width: 80%;
    font-size: 18px;
  }
`;

export const TablaBodyFinanzas = styled.tbody`
  background: #fff;
  padding-left: 2%;
  padding-right: 0%;
  text-align: left;
`;

export const TablaBodyTextoFinanzas = styled.th`
  background: #fff;
  color: #707070;
`;

export const TablaBodyTextoFinanzaPrecio = styled.div`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #0c8de3;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-right: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoFinanzaPagado = styled.div`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #2cb742;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoSaldo = styled.div`
  font-family: Segoe UI;
  font-weight: normal;
  font-size: 12px;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaFinanzas = styled.table`
  margin: 3%;
  width: 72%;
  margin-left: 0%;
  @media ${dispositivo.mobileL} {
    width: 90%;
  }
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`;

export const HeaderContainerDiv = styled.div`
  flex: 50%;
  min-width: 250px;
  align-content: flex-start;
  text-align: left;
`;
export const ButtonContainerDiv = styled.div`
  flex: 50%;
  min-width: 200px;
  align-content: flex-end;
  text-align: right;
`;
